import {Role} from "./role.model"

export class User {
	id: number;
	username: string;
	active: boolean;
	accountExpired: boolean;
	accountLocked: boolean;
	passwordExpired: boolean;
	deletable:boolean;
	roles: Role[];
	firstName:string;
	lastName:string;
	phoneNumber:string;
	verified:boolean;
	roleText:string;
	roleId :number;
	password:string;
	pendingVerification:boolean;
	verifySpinner:boolean;

	constructor(public _username: string) {
        this.username = _username;
    }
}
