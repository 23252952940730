export class ClientError {
	username ?: string = '';
	url ?: string = '';
	file ?: string = '';
	line ?: Number = 0;
	userAgent ?: string = '';
	message ?: string = '';
	stack ?: string = '';
	error ?: string = '';
	code ?: string = '';
	status ?: string = '';
	storage ?: string = '';
}
