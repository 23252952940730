<div class="content-loader body">
  <div *ngIf="!loadingPage">
    <ng-container *ngIf="!completeSignupThankYou">
      <form [formGroup]="userForm">
        <div class="secondary-bg">
          <div class="box-main">
            <div class="box-inner">
              <img class="img-fluid" src="assets/img/ShowConnect-Logo-menu.png">
              <div *ngIf="errorFlag">
                <div class="alert alert-danger text-center">{{errorMsg}}</div>
                <div class="text-center mt-4" style="padding-top: 10px;">
                  <a class="link-muted" routerLink="/login">Click Here</a> to go to login page
                </div>
              </div>
              <div *ngIf="!errorFlag" class="content-box">
                <h5 style=" font-weight:bold; font-size: medium;">Email verification successful!</h5>
                <hr>
                <div class="align-content">
                  <p>Please create a password for your account.</p>
                  <!-- <br>-->
                  <p><u>Password requirements:</u></p>
                  <p>- At least 8 characters long</p>
                  <p>- Doesn't contain parts of your email address or parts of your name </p>
                  <p>- Uses a combination of lowercase letters, uppercase letters, numbers, and symbols.</p>
                </div>
                <div *ngIf="(userForm.controls['password'].touched && userForm.controls['password'].errors)"
                     class="invalid-feedback d-inline">
                  <div *ngIf="userForm.controls['password'].errors.required"
                       class="alert alert-danger w-50 mx-auto">Password
                    is required.
                  </div>
                  <div *ngIf="userForm.controls['password'].errors.pattern || userForm.controls['password'].errors.notUnique"
                       class="alert alert-danger  mx-auto">Password does not meet
                    complexity requirements.
                  </div>
                </div>
                <div *ngIf=" (userForm.controls['confirmPassword'].touched) && userForm.controls['confirmPassword'].errors"
                     class="invalid-feedback d-inline">
                  <div *ngIf="userForm.controls['confirmPassword'].errors.required"
                       class="alert alert-danger w-50 mx-auto">Confirm Password
                    is required.
                  </div>
                  <div *ngIf="userForm.controls['confirmPassword'].errors.stringMatch"
                       class="alert alert-danger w-50 mx-auto">Passwords do not match.
                  </div>
                </div>
                <div class="w-50 m-auto">
                  <div class="login__field">
                    <input autocapitalize="none" class="form-control rounded-0" style="font-size: large;"
                           formControlName="password"  [type]="showNewPassword ? 'text' : 'password'"
                           id="password" maxlength="80"
                           placeholder="New Password" spellcheck="false" type="password">
                    <i alt="hide" class="zwicon zwicon-eye"
                       (click)="showNewPassword = !showNewPassword"
                       [class.hide]="showNewPassword"></i>
                    <i alt="show" class="zwicon zwicon-eye-slash"
                       (click)="showNewPassword = !showNewPassword"
                       [class.hide]="!showNewPassword"></i>
                  </div>

                  <div class="login__field">
                    <input autocapitalize="none" class="form-control rounded-0" style="font-size: large;"
                           formControlName="confirmPassword" [type]="showConfirmPassword ? 'text' : 'password'"
                           id="confirm_password" maxlength="80"
                           placeholder="Confirm Password" spellcheck="false" type="password">
                    <i alt="hide" class="zwicon zwicon-eye"
                       (click)="showConfirmPassword = !showConfirmPassword"
                       [class.hide]="showConfirmPassword"></i>
                    <i alt="show" class="zwicon zwicon-eye-slash"
                       (click)="showConfirmPassword = !showConfirmPassword"
                       [class.hide]="!showConfirmPassword"></i>
                  </div>
                  <button (click)="completeSignup()" [disabled]="!userForm.valid || loading"
                          class="login-button btn btn-primary">
                    <mat-icon *ngIf="saveBtnSpinner"><mat-spinner color="warn" diameter="20"></mat-spinner></mat-icon>Create Account
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="completeSignupThankYou">
      <div class="secondary-bg">
        <div class="box-main">
          <div class="box-inner">
            <img class="img-fluid" src="assets/img/ShowConnect-Logo-menu.png">
            <div class="content-box">
              <h5 style=" font-weight:bold; font-size: medium;">Account activation successful!</h5>
              <hr>
              <p>Your account is now active, <b>{{' ' + userForm.controls['fullName'].value}}</b></p>
            </div>
            <div class="text-center mt-4" style="padding-top: 10px;">
              <a class="link-muted" (click)="navigateToLogin()">Click Here</a> to go to login page
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
