
import {Injectable} from '@angular/core';

export enum ApiType { SECURE= '/api', PROTECTED = '/protected', PUBLIC = '/public' }

export enum ApiMethods { GET = 0, POST = 1, PUT = 2, DELETE = 3, UPLOAD = 4, BLOB = 5 }

@Injectable({
    providedIn: 'root',
})
export class AppConstants {
    public static readonly VALIDATOR_COMMON_PHONE = '[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}';
    public static readonly VALIDATOR_COMMON_EMAIL_PATTERN = '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
    public static readonly VALIDATOR_COMMON_POSTAL_CODE_PATTERN = '[a-z0-9][a-z0-9\\- ]{0,10}[a-z0-9]$';
    public static readonly VALIDATOR_COMMON_NAME = '^[a-zA-Z ]*$'
    public static readonly VALIDATOR_COMMON_NUMBERS = '^[0-9]*$';
    public static readonly VALIDATOR_COMMON_CURRENCY = '^(0|[1-9][0-9]*)$';
    public static readonly VALIDATOR_PASSWORD = '^(?=.*[0-9])(?=.*[a-z]).{8,}$'
    public static readonly VALIDATOR_CHARANDNUMBER='^[#.0-9a-zA-Z\\s,-]+$'
}
