import {Component, OnInit, ViewChild} from '@angular/core';

@Component({
	selector: 'error-404',
	templateUrl: './error.404.component.html',
	styleUrls: ['../error.style.scss']
})

export class Error404Component implements OnInit {

	constructor() {
	}

	ngOnInit(): void {

	}


}

