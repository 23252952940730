import {AbstractControl, FormGroup, ValidatorFn} from '@angular/forms';

export function PasswordUniqueValidator(userControlName,emailControlName,passwordControlName):ValidatorFn{
    return (formGroup: FormGroup) => {
        const UsrNameValue=formGroup.controls[userControlName].value;
        const emailNameValue=formGroup.controls[emailControlName].value
        const pwdValue=formGroup.controls[passwordControlName].value;
         if( pwdValue !=null && pwdValue !=='' && pwdValue.length>=3 && UsrNameValue  !=null && emailNameValue !=null){
             if(formGroup.controls[passwordControlName]?.errors &&
                 !formGroup.controls[passwordControlName]?.errors?.notUnique){
               return ;
             }
             const partsOfThreeLettersOfPwd = pwdValue.match(/.{3}/g).concat(
                 pwdValue.substr(1).match(/.{3}/g),
                 pwdValue.substr(2).match(/.{3}/g) );
             const partsOfThreeLetters = UsrNameValue.match(/.{3}/g).concat(
                 UsrNameValue.substr(1).match(/.{3}/g),
                 UsrNameValue.substr(2).match(/.{3}/g) );
             const partsOfThreeLettersOfEmail = emailNameValue.match(/.{3}/g).concat(
                 emailNameValue.substr(1).match(/.{3}/g),
                 emailNameValue.substr(2).match(/.{3}/g) );

            const checkUniquenessWithUserName=partsOfThreeLettersOfPwd.filter(o1 => partsOfThreeLetters.some(o2 => o1?.toLowerCase() === o2?.toLowerCase()));

            const checkUniquenessWithEmail =partsOfThreeLettersOfPwd.filter(o1 => partsOfThreeLettersOfEmail.some(o2 => o1?.toLowerCase() === o2?.toLowerCase()));

             if((checkUniquenessWithUserName.length>0|| checkUniquenessWithEmail.length>0) ){
                  formGroup.controls[passwordControlName].setErrors({notUnique:true});
             }
             else{
                 formGroup.controls[passwordControlName].setErrors(null);
             }
         }
         return null;
     }
}
