import {Injectable, Injector} from '@angular/core';
import {Meta} from '@angular/platform-browser';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';

import {ApiService} from './api.service';
import {AuthService} from './auth.service';
import {UserService} from './user.service';
import {environment} from '../../../environments/environment';

@Injectable()
export class StartupService {

	// cannot add userService as a constructor parameter because of a cyclical dependency for Http and Router
    // use Injector to get it instead
    constructor(private _injector: Injector) {
	}
	
	public get authService(): AuthService { // this creates userService property
        return this._injector.get(AuthService);
	}
	
	public get userService(): UserService { // this creates userService property
        return this._injector.get(UserService);
    }

    public get apiService(): ApiService { // this creates apiService property
        return this._injector.get(ApiService);
    }

    public get meta(): Meta { // this creates meta property
        return this._injector.get(Meta);
	}
	
	public start(): Promise<any> {

        if (!environment.production) {
            this.meta.addTags([
                {name: 'googlebot', content: 'noindex'},
                {name: 'robots', content: 'noindex'}
            ], true);
        }

        // check if the user has already authenticated
        if (this.authService.isAuthenticated()) {
            // get the current user
            // it is important that this returns a promise to prevent the rest of the app from loading!
            return new Promise((resolve, reject) => {

                this.apiService.getCurrentUser().subscribe(result => {
					// setUser the user observable
					this.userService.setUser(result);
					resolve(result);

                }, (err) => {
                    // if the error is due to authentication issues then clear the session
                    // later, the user will be redirected by the application to the login since the session is now expired
                    if (err.status === 401) {
                        this.userService.requireAuthentication(true);
                    }
                    reject(err);
                });
            });

        } else {
            return Promise.resolve(false);
        }
    }

}