import {Injectable} from '@angular/core';
import * as moment from "moment-timezone";

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    private _LOCAL_STORAGE_USER_REMEMBER_ME: string = 'remember-me';
    private _LOCAL_STORAGE_USER_USERNAME_STRING: string = 'username';
    private _LOCAL_STORAGE_AUTH_TOKEN_ACCESS_STRING: string = 'token-access';
    private _LOCAL_STORAGE_AUTH_TOKEN_REFRESH_STRING: string = 'token-refresh';
    private _LOCAL_STORAGE_AUTH_AUTHORITIES_STRING = 'authorities';
    private _LOCAL_STORAGE_AUTH_REQUIRED_STRING: string = 'authentication-required';
    private _LOCAL_STORAGE_CLIENT_ERROR_EXCLUSIONS: Array<string> = ['token-access', 'token-refresh', 'remember-me'];
    private _LOCAL_STORAGE_EVENT_ID='event-id';
    private _LOCAL_STORAGE_EVENT_TIME_ZONE='event-timezone';

    public initLocalStorage(username: string, access: string, refresh: string) {
        this._set(this._LOCAL_STORAGE_USER_USERNAME_STRING, username);
        this._set(this._LOCAL_STORAGE_AUTH_TOKEN_ACCESS_STRING, access);
        this._set(this._LOCAL_STORAGE_AUTH_TOKEN_REFRESH_STRING, refresh);
    }

    public isLocalStorageUserRemember(): boolean {
        return this._get(this._LOCAL_STORAGE_USER_REMEMBER_ME) === 'true';
    }

    public setLocalStorageUserRemember(value: string) {
        this._set(this._LOCAL_STORAGE_USER_REMEMBER_ME, value);
    }

    public getLocalStorageUsername(): string {
        return this._get(this._LOCAL_STORAGE_USER_USERNAME_STRING);
    }

    public getLocalStorageAccessToken(): string {
        return this._get(this._LOCAL_STORAGE_AUTH_TOKEN_ACCESS_STRING);
    }

    public setLocalStrorageAccessToken(access: string) {
        this._set(this._LOCAL_STORAGE_AUTH_TOKEN_ACCESS_STRING, access);
    }

    public getLocalStorageAccessTokenName(): string {
        return this._LOCAL_STORAGE_AUTH_TOKEN_ACCESS_STRING;
    }

    public getLocalStorageAuthorities(): string[] {
        return JSON.parse(this._get(this._LOCAL_STORAGE_AUTH_AUTHORITIES_STRING));
    }

    public setLocalStorageAuthorities(authorities: string[]) {
        this._set(this._LOCAL_STORAGE_AUTH_AUTHORITIES_STRING, JSON.stringify(authorities));
    }

    public getLocalStorageRefreshToken(): string {
        return this._get(this._LOCAL_STORAGE_AUTH_TOKEN_REFRESH_STRING);
    }

    public setLocalStorageRefreshToken(refresh: string) {
        this._set(this._LOCAL_STORAGE_AUTH_TOKEN_REFRESH_STRING, refresh);
    }

    public getLocalStorageRefreshTokenName(): string {
        return this._LOCAL_STORAGE_AUTH_TOKEN_REFRESH_STRING;
    }

    public isLocalStorageAuthenticationRequired(): boolean {
        return this._get(this._LOCAL_STORAGE_AUTH_REQUIRED_STRING) === 'true';
    }

    public getLocalStorageAuthenticationRequiredName(): string {
        return this._LOCAL_STORAGE_AUTH_REQUIRED_STRING;
    }

    public setLocalStorageAuthenticationRequired() {
        this._store(this._LOCAL_STORAGE_AUTH_REQUIRED_STRING, 'true');
    }

    public clearLocalStorageAuthenticationRequired() {
        this._remove(this._LOCAL_STORAGE_AUTH_REQUIRED_STRING);
    }

    public getLocalStorageClientErrorExclusions(): Array<string> {
        return this._LOCAL_STORAGE_CLIENT_ERROR_EXCLUSIONS
    }

    public setLocalStorageEventId(eventId:string) {
        this._set(this._LOCAL_STORAGE_EVENT_ID, eventId);
    }

    public getLocalStorageEventId():string{
        return this._get(this._LOCAL_STORAGE_EVENT_ID);
    }

    public getLocalStorageEventTimeZoneAbbreviation(year, month):string {

        let timeZone = moment.tz([year, month], this.getLocalStorageEventTimeZone()).zoneAbbr();

       return timeZone;

    }

    public getLocalStorageEventTimeZone():string{
        return this._get(this._LOCAL_STORAGE_EVENT_TIME_ZONE);
    }

    public setLocalStorageEventTimeZone(timezone:string){
        this._set(this._LOCAL_STORAGE_EVENT_TIME_ZONE,timezone);
    }

    public removeLocalStorageEventId(){
         this._remove(this._LOCAL_STORAGE_EVENT_ID);
    }

    public clearSearchResults() {

    }

    public setTablePageSize(size){
        this._set(this._get(this._LOCAL_STORAGE_USER_USERNAME_STRING),size);
    }

    public getTablePageSize(){
        return this._get(this._get(this._LOCAL_STORAGE_USER_USERNAME_STRING));
    }

    public clearLocalStorage() {
        // grab the user name just in case
        let username = this.getLocalStorageUsername();
        let remember = this.isLocalStorageUserRemember();

        // clear it all
        localStorage.clear();
        sessionStorage.clear();

        if (remember) {
            this._set(this._LOCAL_STORAGE_USER_USERNAME_STRING, username);
            this._set(this._LOCAL_STORAGE_USER_REMEMBER_ME, String(remember));
        }
    }

    public setCookie(cname, cvalue, exdays) {
        let d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    public getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    public removeCookie(cname) {
        document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    private _get(name: string): string {
        return localStorage.getItem(name);

    }

    private _set(name: string, value: string) {
        localStorage.setItem(name, value);
    }

    private _remove(name: string) {
        localStorage.removeItem(name);
    }

    private _retrieve(name: string): string {
        return sessionStorage.getItem(name);

    }

    private _store(name: string, value: string) {
        sessionStorage.setItem(name, value);
    }

    private _clear(name: string) {
        sessionStorage.removeItem(name);
    }
}
