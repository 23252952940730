import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {ApiType} from "../../app.constants";


@Injectable({
    providedIn: 'root'
})
export class ProtectedInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.search(ApiType.PROTECTED) >= 0) {
            return next.handle(request.clone({
                setHeaders: {Access: environment.accessKey}
            }));
        }
        return next.handle(request);
    }
}

