import { Component } from '@angular/core';

@Component({
    selector: 'default-layout',
    templateUrl: './default.component.html',
    styleUrls: ['./default.component.scss']
})
export class DefaultLayoutComponent {

    constructor() {
    }
}
