import {Component, Inject, OnInit} from '@angular/core';
import {VerifyStatus} from "../../../infrastructure/services/verify";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../../infrastructure/services/api.service";
import {ApiResponse} from "../../../infrastructure/models/api-response";
import {VerificationRequest} from "../../../infrastructure/models/verification-request";
import {VerificationType} from "../../../infrastructure/models/verification-type";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {StringMatch} from "../../../infrastructure/directives/string-match.directive";
import {AppConstants} from "../../../app.constants";
import {StartupService} from "../../../infrastructure/services/startup.service";
import {PasswordUniqueValidator} from "../../../infrastructure/directives/password-unique.validator";
import {User} from "../../../infrastructure/models/user.model";
import {AuthService} from "../../../infrastructure/services/auth.service";
import {LocalStorageService} from "../../../infrastructure/services/local-storage.service";
import {SessionDataService} from "../../../infrastructure/services/session-data.service";


@Component({
    selector: 'app-verification',
    templateUrl: './verification.component.html',
    styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {
    error = '';
    token: string;
    loading: boolean = false;
    verifyData: VerifyStatus;
    apiResponse: ApiResponse;
    userForm: FormGroup;
    userResponse: User;
    isComplete: boolean = false;
    message: string;
    successMsg: string;
    isFormEnable: boolean;
    isVeriSuccMsg: boolean;
    emailVerificationMsg: string;
    height:number=370
    errorMsg: string = '';
    EmailVerified: boolean = false;
    user: User;
    completeSignupThankYou: boolean = false;

    loadingPage:boolean = true;
    errorFlag:boolean = false;
    showNewPassword: boolean;
    showConfirmPassword: boolean;
    saveBtnSpinner:boolean = false;

    constructor(private route: ActivatedRoute,
                private _apiService: ApiService,
                private _router: Router,
                private _formBuilder: FormBuilder,
                private startupService: StartupService,
                private _storageService:LocalStorageService,
                private router: Router,
                private sessionData: SessionDataService) {

        this.token = this.route.snapshot.paramMap.has("token") ? this.route.snapshot.paramMap.get('token') : '';
        let pathMount = this.router.url;

        if (pathMount.indexOf('complete-signup-thanks') > 0) {
            this._apiService.getCurrentUser().subscribe((user:User)=>{
                if(user){
                    this.user = user;
                }
            });
        }

        this.userForm = this._formBuilder.group({
            password: ['', [Validators.required, Validators.pattern(AppConstants.VALIDATOR_PASSWORD)]],
            confirmPassword: ['', Validators.required],
            username:[null],
            fullName:[null],

        }, {
            validator: [StringMatch('password', 'confirmPassword'),PasswordUniqueValidator('fullName','username','password')]
        });
    }

    ngOnInit(): void {
       /* document.body.style.backgroundColor= "#0a417a";*/
        let pathMount = this.router.url;
        if (pathMount.indexOf('verification') > 0) {
            let request = new VerificationRequest(this.token, VerificationType.REGISTRATION);
            this.route.url.subscribe(res => {
                this.verifyToken(request);
            });
        } else {
            this.completeSignupThankYou = false;
            if (pathMount.indexOf('complete-signup-thanks') > 0) {
                this.completeSignupThankYou = true;
            }
        }
    }

    verifyToken(token: VerificationRequest) {
        this._apiService.verifySignup(token).subscribe({
            next: (res: ApiResponse) => {
                this.loadingPage = false;
                if (res?.success) {
                    this._apiService.getUserByVerificationToken(res?.token?.verification).subscribe((response: User) => {
                        if (response != null) {
                            this.userResponse = response;
                            this.userForm.patchValue({
                                username: this.userResponse.username,
                                fullName: this.userResponse?.firstName +' '+ this.userResponse?.lastName
                            });
                            this.isFormEnable = true;
                            this.isVeriSuccMsg = true;
                            this.emailVerificationMsg = res?.message;
                            this.errorFlag = false;
                        }
                    });
                } else if (res?.status === 'EXPIRED') {
                    this.errorFlag = true;
                    this.errorMsg = "Your account was not verified. Please register again for access to EquiTAPS.";
                    //TODO resend verification token is not implemented yet.
                } else if (res?.status === 'FAILURE') {
                    this.errorFlag = true;
                    this.errorMsg = "Invalid Verification token or token expired.";
                } else {
                    this.apiResponse = res;
                }
                this.loading = false;
            },
            error: err => {
                this.loadingPage = false;
                this.errorFlag = true;
                this.errorMsg = "Your account was not verified. Please register again for access to ShowConnect.";//err.message;
            }
        });

    }


    completeSignup() {
        if (this.userForm.invalid) {
            return;
        }
        if (this.userResponse) {
            this.saveBtnSpinner = true;
            this.loading = true;
            this.userResponse.password = this.userForm.value.password;

            this._apiService.completeUserSignup(this.userResponse).subscribe((userRes: ApiResponse) => {
                this.isComplete = true;
                this.isVeriSuccMsg = false;
                this.isFormEnable = false;
                this.successMsg = userRes.message;
                this.EmailVerified = true;
                this.saveBtnSpinner = false;
                this.loading = false;
                this.completeSignupThankYou= true;
            });
        }
    }

    navigateToLogin() {
        this.sessionData.deselectEvent();
        this._storageService.clearLocalStorage();
        this.router.navigate(['/Login'])
    }

}
