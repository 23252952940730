import {Component, OnInit, ViewChild} from '@angular/core';

@Component({
	selector: 'error-403',
	templateUrl: './error.403.component.html',
	styleUrls: ['../error.style.scss']
})

export class Error403Component implements OnInit {

	constructor() {
	}

	ngOnInit(): void {

	}


}

