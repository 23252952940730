import {ErrorResponse} from "../error-response";

export class AuthenticationResponse {
    success ?: boolean;
    error ?: ErrorResponse;

    constructor(success: boolean, error: ErrorResponse) {
        this.success = success;
        this.error = error;
    }
}
