import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable()
export class Globals {

    private _asyncRouteCount: number = 0;

    private _refreshingToken = new BehaviorSubject<Boolean>(false);
    private _loadingRouteConfig = new BehaviorSubject<Boolean>(false);

    public getRefreshingToken(): BehaviorSubject<Boolean> {
        return this._refreshingToken;
    }

    public setRefreshingToken(loading: boolean) {
        this._refreshingToken.next(loading);
    }

    public isLoadingRoute(): Boolean {
        return this._loadingRouteConfig.getValue();
    }

    public getLoadingRouteConfig(): BehaviorSubject<Boolean> {
        return this._loadingRouteConfig;
    }

    public setLoadingRouteConfig(loading: boolean) {
        this._loadingRouteConfig.next(loading);
    }

    public resetLoadingRouteConfig() {
        this._loadingRouteConfig.next(false);
        this._asyncRouteCount = 0;
    }

    public incrementLoadingRouteCount() {
        this._asyncRouteCount++;
    }

    public decrementLoadingRouteCount() {
        this._asyncRouteCount--;
        // protect against app load initial redirect to dashboard which doesnt include a navigation start event
        if (this._asyncRouteCount < 0) {
            this._asyncRouteCount = 0;
        }
    }

    public getLoadingRouteCount() {
        return this._asyncRouteCount;
    }
}
