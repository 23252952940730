import {VerificationType} from "./verification-type";

export class VerificationRequest {

    verification: string | null;
    verificationType: VerificationType;
    constructor(verification: string | null, verificationType: VerificationType) {
        this.verification = verification;
        this.verificationType = verificationType;
    }
}
