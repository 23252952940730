import {Injectable} from "@angular/core";
import {HttpClient} from '@angular/common/http';
import {throwError} from 'rxjs/internal/observable/throwError';
import {catchError, map} from 'rxjs/operators';
import {environment} from "../../../environments/environment";
import { Observable } from "rxjs";
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { AuthService } from './auth.service';
import {User} from '../models/user.model';
import {ApiMethods} from '../../app.constants';
import {VerificationRequest} from "../models/verification-request";
import {ApiResponse} from "../models/api-response";

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private _baseUri;
    private _loginUri: string;
	private _refreshUri: string;
	protected secure;
    protected  public;
    protected reportServiceUrl;

    constructor(protected http: HttpClient,private _authenticationService: AuthService) {
        this._baseUri = environment.host;
        this.reportServiceUrl = environment.reportService,
        this._loginUri = this._baseUri + '/api/auth/login';
		this._refreshUri = this._baseUri + '/auth/refresh-token';
		this.secure = new this.Secure(this._authenticationService);
        this.public = new this.Public(this._authenticationService);
    }

    public getBaseUri() : string {
        return this._baseUri;
    }

    public loginUri(): string {
        return this._loginUri;
    }

    public refreshUri(): string {
        return this._refreshUri;
	}

	private Secure = class {
        constructor(public _authenticationService: AuthService) {
        }

        get(endpoint: string): Observable<any> {
            return this._authenticationService.secure(endpoint, ApiMethods.GET, null).map(res => res).catch(err => {
                return throwError(err);
            });
        }

        post(endpoint: string, body: any): Observable<any> {
            return this._authenticationService.secure(endpoint, ApiMethods.POST, body).map(res => res).catch(err => {
                return throwError(err);
            });
        }

        put(endpoint: string, body: any): Observable<any> {
            return this._authenticationService.secure(endpoint, ApiMethods.PUT, body).map(res => res).catch(err => {
                return throwError(err);
            });
        }

        delete(endpoint: string): Observable<any> {
            return this._authenticationService.secure(endpoint, ApiMethods.DELETE, null).catch(err => {
                return throwError(err);
            });
        }

        blob(endpoint: string, body: any): Observable<any> {
            return this._authenticationService.secure(endpoint, ApiMethods.BLOB, body).catch(err => {
                return throwError(err);
            });
        }

        upload(endpoint: string, body: any): Observable<any> {
            return this._authenticationService.secure(endpoint, ApiMethods.UPLOAD, body).catch(err => {
                return throwError(err);
            });
        }
	}

    private Public = class {
        constructor(public _authenticationService: AuthService) {
        }

        get(endpoint: string): Observable<any> {
            return this._authenticationService.public(endpoint, ApiMethods.GET, null).pipe(map(res => res), catchError(err => {
                return throwError(err);
            }));
        }

        post(endpoint: string, body: any): Observable<any> {
            return this._authenticationService.public(endpoint, ApiMethods.POST, body).pipe(map(res => res), catchError(err => {
                return throwError(err);
            }));
        }

        put(endpoint: string, body: any): Observable<any> {
            return this._authenticationService.public(endpoint, ApiMethods.PUT, body).pipe(map(res => res), catchError(err => {
                return throwError(err);
            }));
        }

        delete(endpoint: string): Observable<any> {
            return this._authenticationService.public(endpoint, ApiMethods.DELETE, null).pipe(catchError(err => {
                return throwError(err);
            }));
        }

        blob(endpoint: string, body: any): Observable<any> {
            return this._authenticationService.public(endpoint, ApiMethods.BLOB, body).pipe(catchError(err => {
                return throwError(err);
            }));
        }
    }
	
	getCurrentUser(): Observable<User> {
        // get users from api
        return this.http.get<User>(this.getBaseUri() + `/api/private/user`);
	}

    verifySignup(verificationRequest: VerificationRequest): Observable<ApiResponse> {
        return this.public.post('/public/verifications/registration', verificationRequest);
    }

    completeUserSignup(user: User): Observable<ApiResponse> {
        return this.public.put('/public/verifications/registration', user);
    }

    // required get call to update the user with password set at the time of username verification
    getUserByVerificationToken(verificationToken: string): Observable<User> {
        return this.public.get(`/public/verifications/${verificationToken}/user`);
    }

    sendForgotMail(data): Observable<ApiResponse> {
        return this.public.post('/public/verifications/password-reset', data)
    }

    verifyPasswordResetToken(token) {
        return this.public.post('/public/verifications/password-reset/verify-token', token);
    }

    resetPassword(data): Observable<ApiResponse> {
        return this.public.put('/public/verifications/password-reset', data)
    }
	
	
}
