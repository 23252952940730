import {Injectable, Injector} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import 'rxjs/add/observable/empty';
import {catchError, map, mapTo, tap} from 'rxjs/operators';
import {throwError} from 'rxjs/internal/observable/throwError';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {ApiService} from "./api.service";
import {LocalStorageService} from "./local-storage.service";
import {AuthTokenDto} from "../dto/auth/auth-token.dto";
import {environment} from "../../../environments/environment";
import {JwtHelperService} from "@auth0/angular-jwt";
import {AuthenticationResponse} from "../dto/auth/authentication-response";
import {ErrorResponse} from "../dto/error-response";
import {ApiMethods} from '../../app.constants';
import {locale} from "moment/moment";


@Injectable({
    providedIn: 'root'
})
export class AuthService  {

    isLoggedIn = false;
    sessionId?: string;
    refreshSessionId?: string;
    connection = new BehaviorSubject<boolean>(true);
    username?: string;
	private _jwtHelper:JwtHelperService = new JwtHelperService();
	private _baseUri;
	private _loginUri: string;
	private _refreshUri: string;

    constructor( private injector: Injector) {
		this._baseUri = environment.host;
		this._loginUri = this._baseUri + '/api/auth/login';
		this._refreshUri = this._baseUri + '/auth/refresh-token';
        this.sessionId = this.storage.getLocalStorageAccessToken();
        this.refreshSessionId = this.storage.getLocalStorageRefreshToken();

    }

    public get router(): Router {
        return this.injector.get(Router);
	}
	

    public get http(): HttpClient {
        return this.injector.get(HttpClient);
    }


    public get storage(): LocalStorageService {
        return this.injector.get(LocalStorageService);
	}
	
	public loginUri(): string {
        return this._loginUri;
    }

    public refreshUri(): string {
        return this._refreshUri;
	}

    /**
     * Attempt to login with username and password. Stores jwt access/refresh token in local storage.
     *
     *  */
    login(username: string, password: string): Observable<AuthenticationResponse> {
        this.storage.clearLocalStorage();
        this.username = username;
        return this.http.post<any>(
              this._loginUri,
            {
                app: 'USEA',
                username:username,
                password:password
            },
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }
        ).pipe(map((token:AuthTokenDto) => {
                // login successful if there's a jwt token.model.ts in the response
                const access = token.access;
                const refresh = token.refresh;

                this.connection.next(true);
                if(token) {
                    this.storage.initLocalStorage(username, token.access, token.refresh);
                    this.isLoggedIn = true;
                    return new AuthenticationResponse(true,null);
                } else {
                    this.isLoggedIn = false;
                    let errRes = new ErrorResponse();
                    errRes.message = 'Username or password is incorrect';
                    return new AuthenticationResponse(false, errRes);
                }
            }),
            catchError((err: HttpErrorResponse) => {
                this.isLoggedIn = false;
                let errRes = new ErrorResponse();
                // for offline mode
                if (err.status === 0) {
                    this.connection.next(false);
                    errRes.message = 'Could not connect to the Server. Please try again later.';
                } else {
                    errRes.message = err.error.message;
                }

                return of( new AuthenticationResponse(false, errRes));
            })
        );

    }

    /**
     * check if login is valid or if not connected to internet.
     */
    checkLogin(): boolean {
        if (!this.connection.getValue() || this.isLoggedIn) {
            return true;
        }
        this.router.navigate(['authentication']);
        return false;
    }

// 	/**
// 	 * checks previous refresh token, if valid sets isLoggedIn true
// 	 */
// 	checkPreviousLogin(): Promise<boolean> {
// 		this.storage.get('id_token').then((val) => {
// 			this.sessionId = val;
// 		});
// 		return this.storage.get('refresh_id_token').then((val) => {
// 			this.refreshSessionId = val;
// 			const decodeRefreshId = JWT(val);
// 			const decodeId = JWT(this.sessionId);
// 			const currentTime = new Date().getTime() / 1000;
// 			if (decodeRefreshId['exp'] - currentTime > 0) {
// 				// console.log('access token valid for ', (decodeId['exp'] - currentTime) / 60, ' minutes');
// 				// console.log('refresh token valid for ', (decodeRefreshId['exp'] - currentTime) / 3600, ' hours');
// 				this.isLoggedIn = true;
// 				return true;
// 			}
// 			return false;
// 		})
// 			.catch(() => {
// 				return false;
// 			});
// 	}

    /**
     * set local storage access token variables. Decode the jwt just for ease of use.
     */
    public setSessionAccess(access:string) {
        this.sessionId = access;
        this.storage.setLocalStrorageAccessToken(access);
    }

    /**
     * set local storage refresh token variables. Decode the jwt just for ease of use.
     */
    public setSessionRefresh(refresh:string) {
        this.refreshSessionId = refresh;
        this.storage.setLocalStorageRefreshToken(refresh);
    }

    logUserIn() {
        this.router.navigate(['tabs']);
    }

    logUserOut() {
        this.isLoggedIn = false;
        this.sessionId = '';
        this.refreshSessionId = '';
        this.storage.clearLocalStorage();
        this.router.navigate(['authentication']);
    }



    public getAuthToken() {
        return this.sessionId;
    }

    public setAuthToken(token: AuthTokenDto) {
        this.sessionId = token.access;
        this.refreshSessionId = token.refresh;

        this.storage.setLocalStrorageAccessToken(this.sessionId);
        this.storage.setLocalStorageRefreshToken(this.refreshSessionId);
    }

    public isAuthenticated(): boolean {
        const access = this.storage.getLocalStorageAccessToken();
        const refresh = this.storage.getLocalStorageRefreshToken();
        // if there is an access token and the refresh token is not expired
        if (access && refresh && access !== 'null' && refresh !== 'null' && !this._jwtHelper.isTokenExpired(refresh, environment.jwt_expiration_offset_seconds)) {
            // logged in so return true
            return true;
        }
        // not logged in
        return false;
    }

    public _isRefreshRequired(): boolean {
        const access = this.storage.getLocalStorageAccessToken();
        // if there is an access token and the access token is expired
        return !!(access && this._jwtHelper.isTokenExpired(this.storage.getLocalStorageAccessToken(),environment.jwt_expiration_offset_seconds));
    }

    refreshToken() {
        return this.refresh(this.refreshSessionId);
    }

    private refresh(refreshToken: string) {

        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: `Bearer  ${refreshToken}`
            })
        };
        return this.http.get<AuthTokenDto>(this._refreshUri, httpOptions).pipe(
            tap((token: AuthTokenDto) => {
                this.setSessionAccess(token.access);
                this.setSessionRefresh(token.refresh)
                this.isLoggedIn = true;
            }),
            catchError( err => {
                this.isLoggedIn = false;
                throw err; })
        );
	}
	
	public secure(endpoint: string, type: ApiMethods, body: any): Observable<Response> {
        endpoint = this._baseUri + endpoint;
        if (this.isAuthenticated()) {
            return this._call(endpoint, type, body);
        } else { // not authenticated
            this.storage.clearLocalStorage();
            this.router.navigate(['/login']);
            return Observable.empty();
        }
	}

    public public(endpoint: string, type: ApiMethods, body: any): Observable<Response> {
        endpoint = this._baseUri + endpoint;
        return this._call(endpoint, type, body);
    }
	
	public _call(endpoint: string, type: ApiMethods, body): Observable<any> {
        const options = {params: new HttpParams(), headers: new HttpHeaders()
                .append('Content-Type', 'application/json')
        };
        switch (type) {
            case ApiMethods.GET: {
                return this.http.get(endpoint, options).catch(this.onError);
            }
            case ApiMethods.DELETE: {
                return this.http.delete(endpoint, options).catch(this.onError);
            }
            case ApiMethods.POST: {
                return this.http.post(endpoint, body, options).catch(this.onError);
            }
            case ApiMethods.PUT: {
                return this.http.put(endpoint, body, options).catch(this.onError);
            }
            case ApiMethods.UPLOAD: {
                return this.http.post(endpoint, body).catch(this.onError);
            }
            case ApiMethods.BLOB: {
                const options = {params: new HttpParams(), headers: new HttpHeaders()
                        .append('Content-Type', 'application/json')
                        .append('responseType', 'blob')
                };
                return this.http.post(endpoint, body, options).catch(this.onError);
            }
            default: {
                return this.http.get(endpoint, options).catch(this.onError);
            }
        }
	}

	private onError(error) {

        let body = error;

        if (error && error.error) {
            try {
                body = error;
                body.message = body.message;
                body.error = error.url
            } catch (e) {
                body = error;
            }
        }

        return throwError(body);
    }
	
}
