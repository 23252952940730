import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {UserService} from '../services/user.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private _router: Router, private _userService: UserService) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): boolean {

        // check to see if user is logged in
        if (this._userService.isAuthenticated()) {

            const roles = this._userService.roles();
            // see if any authorities/authorities were defined on the route
            const authorities = route.data['roles'] as Array<string>;

			//logic here for restricting routes
        }

        // not logged in so redirect to login page
        this._router.navigate(['']);
        return false;
    }
}
