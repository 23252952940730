import {Injectable} from '@angular/core';
import {BehaviorSubject, ReplaySubject, Subscription} from 'rxjs';
import {UseaEvent} from '../dto/usea-event';
import {Entry} from '../models/entry.model';
import {TableData} from '../models/table-data';
import {MatTableDataSource} from '@angular/material/table';
import {take} from 'rxjs/operators';
import {EventHeaderSummary} from "../dto/event-header-summary.dto";
import {EntryAcceptance} from "../dto/enumerated/entry-acceptance.dto";
import {LevelType} from "../dto/enumerated/level-type.dto";
import {EventService} from "./event.service";
import {LocalStorageService} from "./local-storage.service";
import {PageEvent} from "@angular/material/paginator";

@Injectable({
    providedIn: 'root'
})
export class SessionDataService {
    eventId: number;
    selectedEvent = new ReplaySubject<any>(1);
    selectedEventObj = new ReplaySubject<any>(1);
    myEvents = new ReplaySubject<Array<EventHeaderSummary>>(1);
    eventSetup = new ReplaySubject<UseaEvent[]>(1);
    buildEntry = new ReplaySubject<Entry>(1);
    pageSize = 5;
    selectedOfficials = new BehaviorSubject<TableData>({
        dataSource: new MatTableDataSource([]),
        displayedColumns: ['role', 'name', 'useaId', 'usefId', 'feiId', 'email', 'phone', 'state'],
        displayedColumnHeaders: ['Role', 'Name', 'USEA #', 'USEF #', 'FEI #', 'Email', 'Phone', 'State'],
        tableHeaderText: ''
    });

    selectedJudges = new BehaviorSubject<TableData>({
        dataSource: new MatTableDataSource([]),
        displayedColumns: ['role', 'name', 'useaId', 'usefId', 'feiId', 'state'],
        displayedColumnHeaders: ['Role', 'Name', 'USEA #', 'USEF #', 'FEI #', 'State'],
        tableHeaderText: ''
    });

    selectedOfficialsSubscription$: Subscription;

    constructor(private eventService: EventService, private storage: LocalStorageService,) {
        this.eventId = Number(this.storage.getLocalStorageEventId());
        this.myEvents.next([]);
    }

    addEvent() {

    }

    selectEvent() {
        this.selectedEvent.next(true);
    }

    deselectEvent() {
        this.selectedEvent.next(false);
    }

    getEventStatistics(){
        this.eventId = Number(this.storage.getLocalStorageEventId());
        if (this.eventId) {
            this.eventService.getEventStatistics(this.eventId).subscribe(eRes => {
                this.selectedEventObj.next(eRes);
            });
        }
    }

    deselectEventStatistics(){
        this.selectedEventObj.next(null);
    }

    addOfficial(official) {
        this.selectedOfficials.pipe(
            take(1)
        ).subscribe(
            (officialsTableData) => {
                officialsTableData.dataSource.data = officialsTableData.dataSource.data.concat(official);
                officialsTableData.dataSource._updateChangeSubscription();
                this.selectedOfficials.next(officialsTableData);
            }
        );
    }

    addJudge(judge) {
        this.selectedJudges.pipe(
            take(1)
        ).subscribe(
            (judgesTableData) => {
                judgesTableData.dataSource.data = judgesTableData.dataSource.data.concat(judge);
                judgesTableData.dataSource._updateChangeSubscription();
                this.selectedJudges.next(judgesTableData);
            }
        );
    }

    handlePageEvent(event: PageEvent){
        this.storage.setTablePageSize(event.pageSize);
    }
}
