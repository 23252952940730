import {HttpClient,HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {AuthService} from "../services/auth.service";

import {LocalStorageService} from "../services/local-storage.service";
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";
import {Globals} from "../../app.globals";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Observable, throwError} from "rxjs";
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/skip';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import {catchError} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {Token} from '../models/token.model'


@Injectable({
    providedIn:"root"
})
export class TokenInterceptor implements HttpInterceptor {
    _debug: boolean = true;
    jwtHelper: JwtHelperService;

    constructor(public http: HttpClient,
                public authService: AuthService, private storage: LocalStorageService,private _globals: Globals, private _router: Router
    ) {
		this.jwtHelper = new JwtHelperService();
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const access = this.storage.getLocalStorageAccessToken();
        const refresh = this.storage.getLocalStorageRefreshToken();

        if ((!access && !refresh) || request.url === this.authService.refreshUri() || request.url.includes("craftmypdf-gen")) {
            // continue without authorization header
            return next.handle(request).catch((error) => throwError(error));
        }

        // add authorization header
        request = this._addAuthenticationHeader(request);

        // if the refresh token is expired then send to the login
        if (this.jwtHelper.isTokenExpired(refresh, environment.jwt_expiration_offset_seconds)) {
            if (this._debug) {
                console.log(request.url, ' intercepted and sending to login with expired refresh token: ', refresh);
            }
			this._router.navigate(['/login']);
            return Observable.empty();
        } else if (this.jwtHelper.isTokenExpired(access, environment.jwt_expiration_offset_seconds)) { // if the access token is expired
            if (this._debug) {
                console.log(request.url, ' intercepted with expired access token: ', access);
            }

            return this._globals.getRefreshingToken().first().flatMap(refreshing => {
                if (refreshing) {
                    if (this._debug) {
                        console.log('refresh token request already in progress...');
                    }
                    // Wait for new token before sending the request
                    return this._globals.getRefreshingToken().skip(1).flatMap(refreshing => {
                        if (this._debug) {
                            console.log('refresh token complete. resuming call: ', request.url);
                        }
                        return next.handle(this._addAuthenticationHeader(request)).catch((error) => Observable.throwError(error));
                    });

                } else {
                    if (this._debug) {
                        console.log('refreshing access token...');
                    }
                    // refresh token if expired and not already refreshing
                    this._globals.setRefreshingToken(true);
                    return this._refresh().flatMap(
                        res => {
                            this.storage.initLocalStorage(this.storage.getLocalStorageUsername(), res['access'], this.storage.getLocalStorageRefreshToken());
                            this._globals.setRefreshingToken(false);
                            if (this._debug) {
                                console.log('resuming original request: ', request.url, ' with new token: ', this.storage.getLocalStorageAccessToken());
                            }
                            return next.handle(this._addAuthenticationHeader(request)).catch((error) => Observable.throwError(error));
                        }).catch((error) => {
                        this._globals.setRefreshingToken(false);
                        return Observable.throwError(error);
                    });
                }
            });
        } else {
            if (this._debug) {
                // console.log('intercepted request: ', request.url, ' has valid access token: ', access);
            }
            return next.handle(request);
        }
    }

    private _addAuthenticationHeader(request) {

        // TODO: only send token on private API calls

        const token = this.storage.getLocalStorageAccessToken();

        if (token) {
            return request.clone({
                setHeaders: {
                    'Authorization': `Bearer ${token}`
                }
            });
        }
        return request;
    }

    private _refresh(): Observable<any> {
		return this.http.post(this.authService.refreshUri() , new Token('', this.storage.getLocalStorageRefreshToken()));
    }
}

