<div class="error-wrapper">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center" fxFlex="100%">
        <div fxFlexAlign="center" fxLayout="row">
            <div fxFlex class='logo-background' fxFlexAlign="center">
                <img src="assets/img/Event_Management_System.png" />
            </div>
            <div class='row error-text'>
                <h1>
                    Oops! Something went wrong.
                </h1>
                <h3>
                    Please contact customer support.
                </h3>
                <h3>
                    Click <a [routerLink]="['/Dashboard/MyEvents']"> here </a> to return to the dashboard.
                </h3>
            </div>
        </div>
    </div>
</div>



