import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {Error000Component} from "./modules/error/000/error.000.component";
import {Error403Component} from "./modules/error/403/error.403.component";
import {Error404Component} from "./modules/error/404/error.404.component";
import {Error500Component} from "./modules/error/500/error.500.component";
import {VerificationComponent} from "./modules/sign-in/verification/verification.component";
import {DefaultLayoutComponent} from "./layouts/default.component";

const defaultRoutes: Routes = [

    {
        path: 'verification',
        loadChildren: () => import('./modules/sign-in/verification/verification.module').then(m => m.VerificationModule)
    },
    {
        path: 'password-reset',
        loadChildren: () => import('./modules/sign-in/password-reset/password-reset.module').then(m => m.PasswordResetModule)
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('./modules/sign-in/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
    },

];

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./modules/sign-in/sign-in.module').then(m => m.SignInModule)
    },
    {
        path: 'Dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'Profile',
        loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule)
    },

    {path: 'verification/:token', component: VerificationComponent, pathMatch: 'full'},
    {path: 'password-reset?token=:id', redirectTo: '/password-reset', pathMatch: 'full'},
    {path: '', component: DefaultLayoutComponent, children: defaultRoutes},
    {path: 'error/000', component: Error000Component},
    {path: 'error/403', component: Error403Component},
    {path: 'error/404', component: Error404Component},
    {path: 'error/500', component: Error500Component},
    {path: 'error', redirectTo: 'error/500'},
    {
        path: '**',
        loadChildren: () => import('./modules/sign-in/sign-in.module').then(m => m.SignInModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true}
        // { relativeLinkResolution: 'corrected' }
    )],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
