import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ThemeService} from './infrastructure/services/theme.service';
import {OverlayContainer} from '@angular/cdk/overlay';
import {Router} from '@angular/router';
import { UserService } from './infrastructure/services/user.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
    title = 'USEA';

    constructor(
        public themeService: ThemeService,
        private overlayContainer: OverlayContainer,
		private router: Router,
		private _userService:UserService
    ) {
    }

    ngOnInit() {
        this.themeService.darkTheme.subscribe((val) => {
            if (val) {
                this.overlayContainer.getContainerElement().classList.add('theme-alternate');
                this.overlayContainer.getContainerElement().classList.remove('default-theme');
            } else {
                this.overlayContainer.getContainerElement().classList.add('default-theme');
                this.overlayContainer.getContainerElement().classList.remove('theme-alternate');
            }
		});
		
		// this logic is heavily tied to the startup service that will populate user details

        // if the user experienced an authentication issue (401) during the startup service
        if (this._userService.isAuthenticationRequired()) {
            // clear the required authentication
            this._userService.requireAuthentication(false);
            return this.router.navigate(['login'], {replaceUrl: true});
        } else
            // if the user is authenticated, but the user data is not loaded from the
            // StartupService then there must be an error, so navigate to system error page
        if (this._userService.isAuthenticated() && !this._userService.isPopulated()) {
            return this.router.navigate(['error/500'], {replaceUrl: true});
        }
    }
}
