import {ErrorHandler, Injectable, Injector, NgZone} from '@angular/core';
//import {StorageService} from "./storage.service";
import {Router} from "@angular/router";
import {HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {ClientError} from '../dto/client-error.dto';
import {LocalStorageService} from "../services/local-storage.service";
import {ErrorService} from "../errorHandler/error.service";
import {ErrorLoggingService} from "../errorHandler/error-logging.service";
import {environment} from "../../../environments/environment";
import {throwError} from "rxjs";
import {Globals} from "../../app.globals";

@Injectable({
    providedIn: 'root'
})
export class GlobalErrorHandler extends ErrorHandler {

    httpClient: HttpClient = null;

    constructor(private handler: HttpBackend, private _injector: Injector, private _globals: Globals, private _storageService: LocalStorageService, private injector: Injector) {
        // The true parameter tells Angular to rethrow exceptions, so operations like 'bootstrap' will result in an error
        // when an error happens. If we do not rethrow, bootstrap will always succeed.
        super();
        this.httpClient = new HttpClient(handler);
    }

    get ngZone(): NgZone {
        return this._injector.get(NgZone);
    };

    get router(): Router {
        return this._injector.get(Router);
    };

    handleError(error) {

        // since we experienced an error we need to handle our routing config of lazy loading activity indicators
        // this._globals.resetLoadingRouteConfig();

        // if the error was a chunk loading error then reload the page
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error.message)) {
            return location.reload(true);
        }

        // if the error status is set to zero then there was a connection failure
        if (error.status === 0 || error.status == undefined) {
            this.ngZone.run(() => this.router.navigate(['/error'], {replaceUrl: false})).then();
        }

        // authentication errors should be getting handled by the app itself (Clearing the session tokens and sending to the user login).
        // but there is a case where a user can receive a 401 with a JWTExpiredToken exception if their client time suffers from a large offset or
        // if a the startup service attempts a call with an invalid username (database refresh or user deactivation)
        // that is handled here to redirect them to the login screen.
        if (error.status === 401) {
            this._storageService.clearLocalStorage();
            return this.ngZone.run(() => this.router.navigate(['/login'], {replaceUrl: true})).then();
        }

        // if forbidden then forward to proper place before we attempt to log anything
        if (error.status === 403) {
            return this.ngZone.run(() => this.router.navigate(['/error/403'], {replaceUrl: false})).then();
        }

        // create a client error object.
        const clientError: ClientError = new ClientError();

        // construct an array of local storage parameters to log
        const excluded = this._storageService.getLocalStorageClientErrorExclusions();
        const local = Object.keys(localStorage).reduce(function (obj, str) {
            if (excluded.indexOf(str) < 0) {
                obj[str] = localStorage.getItem(str);
            }
            return obj
        }, {});

        clientError.username = this._storageService.getLocalStorageUsername() || '';
        clientError.url = window.location.href;
        clientError.file = error.fileName;
        clientError.line = error.lineNumber;
        clientError.userAgent = window.navigator.userAgent;
        clientError.message = error.message;
        clientError.stack = error.stack;
        clientError.error = JSON.stringify(error.error); // this is mapped to string on api
        clientError.code = error.code;
        clientError.status = error.status;
        clientError.storage = JSON.stringify(local);

        // send to a nice error page
        this.ngZone.run(() => this.router.navigate(['/error'], {replaceUrl: false})).then();

        // send the error to the server for logging if it was not a connection failure
        if (error.status !== 0) {
            this.send(clientError);
        }

        // delegate the error to the default handler
        super.handleError(error);
    }

    private send(error: ClientError) {

        const auditEndpoint: string = environment.host + '/public/system/client-errors';

        const options = {
            params: new HttpParams(), headers: new HttpHeaders()
                .append('Content-Type', 'application/json')
        };

        // record the client error
        return this.httpClient.post(auditEndpoint, JSON.stringify(error), options).subscribe(data => {
        }, e => {
            console.error('Error sending client error to server', e);
            return throwError(e);
        });
    }
}
