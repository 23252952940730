import {Component, OnInit, ViewChild} from '@angular/core';

@Component({
	selector: 'error-500',
	templateUrl: './error.500.component.html',
	styleUrls: ['../error.style.scss']
})

export class Error500Component implements OnInit {

	constructor() {
	}

	ngOnInit(): void {

	}


}

