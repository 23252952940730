import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule, APP_INITIALIZER, Injector} from '@angular/core';

import {StartupService} from './infrastructure/services/startup.service'
import {AuthGuard} from './infrastructure/guards/auth.guard'

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialFileInputModule} from 'ngx-material-file-input';
import {DatePipe, TitleCasePipe} from "@angular/common";
import {TokenInterceptor} from "./infrastructure/interceptor/token.interceptor";
import {Error403Component} from "./modules/error/403/error.403.component";
import {Error404Component} from "./modules/error/404/error.404.component";
import {Error500Component} from "./modules/error/500/error.500.component";
import {Error000Component} from "./modules/error/000/error.000.component";
import {Error400Component} from "./modules/error/400/error.400.component";
import {GlobalErrorHandler} from "./infrastructure/util/error-handler.service";
import {ProtectedInterceptor} from "./infrastructure/interceptor/protected.interceptor";
import {Globals} from "./app.globals";
import {AppConstants} from "./app.constants";
import {DragDropModule} from '@angular/cdk/drag-drop';
import { from } from 'rxjs';
import {MAT_RADIO_DEFAULT_OPTIONS} from "@angular/material/radio";
import {DefaultLayoutComponent} from "./layouts/default.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";


// used to gather user information for previously authenticated user
export function startupServiceFactory(_startupService: StartupService): Function {
    return () => _startupService.start().catch(error => {
        console.error('Error during StartupService', error);
    });
}

@NgModule({
    declarations: [
        AppComponent,
        Error000Component,
        Error400Component,
        Error403Component,
        Error404Component,
        Error500Component,
        DefaultLayoutComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MaterialFileInputModule,
        DragDropModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [
        DatePipe,
        Storage,
        TitleCasePipe,
        Globals,
        AppConstants,
        AuthGuard,
        StartupService,
        {provide: HTTP_INTERCEPTORS, useClass: ProtectedInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        {provide: APP_INITIALIZER, useFactory: startupServiceFactory, deps: [StartupService, Injector], multi: true},
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        {provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: {color: 'primary'}}
    ],
    exports: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
    }
}
