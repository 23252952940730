import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";
import { Country } from "../models/country.model";
import { State } from "../models/state.model";
import { Events } from '../models/event.model';
import { EventFinder } from '../dto/event-finder.dto';
import { JudgeFinder } from '../dto/judge-finder.dto';
import { OfficialFinder } from '../dto/official-finder.dto';
import { Person } from '../models/person.model';
import { Judge } from '../models/judge.model';
import { Official } from '../models/official.model';
import { Facility } from "../models/facility.model";
import { Link } from "../models/link.model";
import { EventSetupBasic } from "../dto/event-setup-basic.dto";
import { Fee } from "../models/fee.model";
import { SpecialAward } from "../models/specialAward.model";
import { Telephone } from '../models/telephone.model';
import { Email } from '../models/email.model';
import { Level } from '../models/level.model';
import { AuthService } from './auth.service';
import {EventHeaderSummary} from "../dto/event-header-summary.dto";
import {UseaEvent} from "../dto/usea-event";
import {Teams} from "../dto/teams.dto";
import {EventCurrentStatus} from "../dto/event-current-status.dto";
import {FeeSummary} from "../dto/fee-summary.dto";
import {EventFeeSummary} from "../dto/event-fee-summary.dto";
import {Role} from "../models/role.model";
import {UserFinder} from "../dto/user-finder.dto";
import {User} from "../models/user.model";
import {ApiResponse} from "../models/api-response";
import {EventUser} from "../dto/event-user.dto";
import {RecentEvents} from "../models/recentevents.model";


@Injectable({
    providedIn: 'root'
})
export class EventService extends ApiService {

    constructor(http: HttpClient, authService: AuthService) {
        super(http,authService);
    }

    getEventStatistics(eventId: number): Observable<EventHeaderSummary> {
        if (eventId) {
            return this.secure.get(`/api/event/${eventId}/event-statistics`);
        }
    }

    getEventSetupBasic(eventId: number): Observable<EventSetupBasic> {
        if (eventId) {
            return this.secure.get(`/api/event/info/${eventId}/setup`);
        }
    }
    getMyEventsSummary(): Observable<Array<EventHeaderSummary>> {
        return this.secure.get(`/api/private/user/events`);
    }
    updateEvent(eventId, basicInfo): Observable<Events> {
        return this.secure.put(`/api/event/info/${eventId}`, basicInfo);
    }

    getAllCountries(): Observable<Country[]> {
        return this.secure.get(`/api/country/`);
    }

    getAllStatesByCountry(countryId: number): Observable<State[]> {
        return this.secure.get(`/api/state/${countryId}/country`);
    }

    searchEvents(eventFinder: EventFinder, userId: number): Observable<Events[]> {
        return this.secure.post(`/api/event/finder/${userId}`, eventFinder);
    }

    getRecentEventList(userId: number): Observable<RecentEvents[]> {
        console.log(userId);
        return this.secure.get(`/api/event/recent-events/${userId}`);
    }

    searchJudges(judgeFinder: JudgeFinder): Observable<Person[]> {
        return this.secure.post(`/api/event/judge/finder`, judgeFinder);
    }

    getJudges(eventId: number): Observable<Judge[]> {
        if (eventId) {
            return this.secure.get(`/api/event/${eventId}/judge`);
        }
    }

    getjudgeByOfficial(officialId: number): Observable<Judge[]> {
        return this.secure.get(`/api/event/judge/${officialId}/official`);
    }

    saveJudge(judge: Judge): Observable<Judge> {
        return this.secure.post(`/api/event/judge`, judge);
    }

    updateJudge(judgeId: number, judge: Judge): Observable<Judge> {
        if (judgeId) {
            return this.secure.put(`/api/event/judge/${judgeId}`, judge);
        }
    }

    deleteJudge(judgeId: number): Observable<any> {
        if (judgeId) {
            return this.secure.delete(`/api/event/judge/${judgeId}`);
        }
    }

    getOfficialsByEventIdAndType(eventId: number, type: string): Observable<Official[]> {
        return this.secure.get(`/api/event/${eventId}/official/${type}`);
    }

    getOfficialByEventAndPersonAndType(eventId: number, personId: number,type:string): Observable<Official> {
        return this.secure.get(`/api/event/${eventId}/person/${personId}/${type}`);
    }

    saveOfficial(official: Official): Observable<Official> {
        return this.secure.post(`/api/event/official`, official);
    }

    updateOfficial(officialId:number, official: Official): Observable<Official> {
        if (officialId) {
            return this.secure.put(`/api/event/official/${officialId}`, official);
        }
    }

    deleteOfficial(officialId: number): Observable<any> {
        if (officialId) {
            return this.secure.delete(`/api/event/official/${officialId}`);
        }
    }

    deleteJudgeByOfficial(officialId: number): Observable<any> {
        if (officialId) {
            return this.secure.delete(`/api/event/official/${officialId}/judge`);
        }
    }

    uploadStableMap(payload): Observable<any> {
        return this.secure.upload(`/api/event/upload-stable-map`, payload);
    }

    uploadFacilityMap(payload): Observable<any> {
        return this.secure.upload(`/api/event/upload-facility-map`, payload);
    }
    saveEvent(basicInfo): Observable<Events> {
        return this.secure.post(`/api/event/info`, basicInfo);
    }
    saveEventFacility(facilityObj:Facility): Observable<Facility> {
        return this.secure.post(`/api/event/facility`, facilityObj);
    }
    updateEventFacility(facilityId:number, facilityObj:Facility): Observable<Facility> {
        return this.secure.put(`/api/event/facility/${facilityId}`, facilityObj);
    }

    saveEventLink(linkObj: Link): Observable<Link> {
        if (linkObj.id) {
            return this.secure.put(`/api/event/link/${linkObj.id}`, linkObj);
        } else {
            return this.secure.post(`/api/event/link`, linkObj);
        }

    }
    updateEventLink(linkId:number, linkObj:Link): Observable<Link> {
        return this.secure.put(`/api/event/link/${linkId}`, linkObj);
    }
    deleteEventLink(linkId:number): Observable<any> {
        return this.secure.delete(`/api/event/link/${linkId}`);
    }

    searchEventFees(eventId: number): Observable<Fee[]> {
        return this.secure.get(`/api/event/${eventId}/fee`);
    }

    createFees(fee: Fee): Observable<Fee> {
        return this.secure.post(`/api/event/fee`, fee);
    }

    deleteFees(feeId: number) {
        return this.secure.delete(`/api/event/fee/${feeId}`);
    }

    updateFees(fee: Fee, feeId: number): Observable<Fee> {
        return this.secure.put(`/api/event/fee/${feeId}`, fee);
    }

    searchSpecialAwards(eventId: number): Observable<SpecialAward[]> {
        return this.secure.get(`/api/event/${eventId}/special-award`);
    }
    createSpecialAward(award: SpecialAward): Observable<SpecialAward> {
        return this.secure.post(`/api/event/special-award`, award);
    }

    updateSpecialAward(award: SpecialAward, awardId: number): Observable<SpecialAward> {
        return this.secure.put(`/api/event/special-award/${awardId}`, award);
    }

    deleteSpecialAwards(awardId: number) {
        return this.secure.delete(`/api/event/special-award/${awardId}`);
    }

    searchOfficials(officialFinder: OfficialFinder): Observable<Person[]> {
        return this.secure.post(`/api/event/official/finder`, officialFinder);
    }

    uploadEventLogo(payload): Observable<any> {
        return this.secure.upload(`/api/event/upload-event-logo`, payload);
    }

    getUniqueLevelCodesOfEvent(eventId:number): Observable<String[]> {
        if (eventId) {
            return this.secure.get(`/api/event/${eventId}/level-codes`);
        }
    }

    // getEventImage(eventId: number): Observable<Blob> {
    //     return this.secure.get(`/api/event/get-event-logo?event-id=${eventId}`, { responseType: 'blob' as 'json' });
    // }

    // getFacilityMap(facilityId: number): Observable<Blob> {
    //     return this.secure.get(`/api/event/get-facility-map?facility-id=${facilityId}`, { responseType: 'blob' as 'json' });
    // }

    // getStableMap(facilityId: number): Observable<Blob> {
    //     return this.secure.get(`/api/event/get-stable-map?facility-id=${facilityId}`, { responseType: 'blob' as 'json' });
	// }
	
	getEventImage(eventId: number): Observable<Blob> {
        // return this.http.get<Blob>(this.getBaseUri() + `/api/event/get-event-logo?event-id=${eventId}`, { responseType: 'blob' as 'json' });
        return new Observable<Blob>();
    }

    getFacilityMap(facilityId: number): Observable<Blob> {
        // return this.http.get<Blob>(this.getBaseUri() + `/api/event/get-facility-map?facility-id=${facilityId}`, { responseType: 'blob' as 'json' });
        return new Observable<Blob>();
    }

    getStableMap(facilityId: number): Observable<Blob> {
        // return this.http.get<Blob>(this.getBaseUri() + `/api/event/get-stable-map?facility-id=${facilityId}`, { responseType: 'blob' as 'json' });
        return new Observable<Blob>();
    }


    getOfficials(eventId: number): Observable<Official[]> {
        if (eventId) {
            return this.secure.get(`/api/event/${eventId}/official`);
        }
    }

    getDivisionFeesOfEvent(eventId: number): Observable<EventFeeSummary[]> {
        if (eventId) {
            return this.secure.get(`/api/event/${eventId}/division-fees`);
        }
    }

    //--------------------------------Person API

    getPerson(personId): Observable<Person> {
        if (personId) {
            return this.secure.get(`/api/person/${personId}`);
        }
    }

    savePerson(person: Person): Observable<Person> {
        return this.secure.post(`/api/person/`, person);
    }

    savePersonWithContactDtls(person: Person): Observable<Person> {
        if (person) {
            return this.secure.post(`/api/person/person-with-contactdtls`, person);
        }
    }

    updatePerson(personId:number, person: Person): Observable<Person> {
        if (personId) {
            //return this.secure.post<Judge>(this.getBaseUri()+`/api/person/${personId}`);
            return this.secure.put(`/api/person/${personId}`, person);
        }
    }
    deletePerson(personId: number) {
        if (personId) {
            this.secure.delete(`/api/person/${personId}`);
        }
    }

    getTelephonesByPerson(personId: number): Observable<Telephone[]> {
        if (personId) {
            return this.secure.get(`/api/person/${personId}/telephone`);
        }
    }

    getTelephone(telephoneId: number): Observable<Telephone> {
        return this.secure.get(`/api/person/telephone/${telephoneId}`);
    }

    saveTelephone(telephone: Telephone): Observable<Telephone> {
        if (telephone.id) {
            return this.secure.put(`/api/person/telephone/${telephone.id}`, telephone);
        } else {
            return this.secure.post(`/api/person/telephone`, telephone);
        }
    }

    deleteTelephone(telephoneId: number): Observable<any> {
        if (telephoneId) {
            return this.secure.delete(`/api/person/telephone/${telephoneId}`);
        }
    }

    getEmailsByPerson(personId: number): Observable<Email[]> {
        if (personId) {
            return this.secure.get(`/api/person/${personId}/email`);
        }
    }

    getEmail(emailId: number): Observable<Email> {
        return this.secure.get(`/api/person/email/${emailId}`);
    }

    saveEmail(email: Email): Observable<Email> {
        if (email.id) {
            return this.secure.put(`/api/person/email/${email.id}`, email);
        } else {
            return this.secure.post(`/api/person/email`, email);
        }
    }


    deleteEmail(emailId: number): Observable<any> {
        if (emailId) {
            return this.secure.delete(`/api/person/email/${emailId}`);
        }
    }

    getEventLevels(eventId: number):Observable<Level[]>{
        return this.secure.get(`/api/event/${eventId}/levels`);
    }

    getEventStatus(eventId: number): Observable<EventCurrentStatus> {
        if (eventId) {
            return this.secure.get(`/api/event/${eventId}/status`);
        }
    }

    /*Account Security Calls*/
    getAllUsers(): Observable<User[]> {
        return this.secure.get(`/api/private/users`);
    }

    getAllRoles(): Observable<Role[]> {
        return this.secure.get(`/api/roles/`);
    }

    searchUsers(userFinder: UserFinder): Observable<User[]> {
        return this.secure.post(`/api/private/user/finder`, userFinder);
    }

    isUserExist(userName): Observable<boolean> {
        return this.secure.get(`/api/private/user/verify-username/` + userName)
    }

    signupUser(user: User): Observable<ApiResponse> {
        return this.secure.post(`/api/private/user/signup`, user);
    }

    sendVerificationEmailForUser(userName): Observable<ApiResponse> {
        return this.secure.put(`/api/private/user/send-verification/`+ userName);
    }

    getUsersByEvent(eventId: number): Observable<EventUser[]> {
        return this.secure.get(`/api/event/${eventId}/users`);
    }

    getUserByEventAndRole(eventId: number, userId: number,role:string): Observable<EventUser[]> {
        return this.secure.get(`/api/event/${eventId}/user/${userId}/${role}`);
    }

    saveEventUser(user: EventUser): Observable<any> {
        return this.secure.post(`/api/event/event-user`, user);
    }

    deleteEventUser(userId: number,role:string, eventId: number): Observable<any> {
        if (userId) {
            return this.secure.delete(`/api/event/${eventId}/delete-user/${userId}/${role}`);
        }
    }

}
