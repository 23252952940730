import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';

import {AuthService} from "./auth.service";
import {LocalStorageService} from './local-storage.service';

import {User} from '../models/user.model';
import {Role} from '../models/role.model';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	private _currentUser = new BehaviorSubject<User>(null);
    currentUser$ = this._currentUser.asObservable();

	constructor(private _AuthService: AuthService, private _LocalStorageService:LocalStorageService) { }

	public setUser(user: User) {
        this._currentUser.next(user);
	}

	public getCurrent(): User {
        return this._currentUser.getValue();
    }

	public isAuthenticated() {
        return this._AuthService.isAuthenticated();
    }
	
	public requireAuthentication(value: boolean): void {
        if (value) {
            this.logout();
        }
	}

	public roles(): Role[] {
        return this.getCurrent() ? this.getCurrent().roles : [];
	}
	
	public isAuthenticationRequired(): boolean {
        return this._LocalStorageService.isLocalStorageAuthenticationRequired();
    }
	
	public isPopulated(): boolean {
        return this._currentUser.getValue() ? true : false;
    }

	public logout(): void {
        this._LocalStorageService.clearLocalStorage();
        this._currentUser.next(null);
        this._currentUser.next(new User(this._LocalStorageService.getLocalStorageUsername()));
    }
}
