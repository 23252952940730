import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    public darkTheme: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() {
    }

    toggleDarkTheme() {
        this.darkTheme.next(!this.darkTheme.getValue());
    }
}
